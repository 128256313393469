<template>
  <footer class="footer">
    <div class="footer__w">
      <div class="callcenter">
        <a
          class="callcenter__link"
          :href="`tel:${_T('@Call center number')}`"
          >{{ _T("@Call center number") }}</a
        >
        <div class="callcenter__schedule">
          <span class="callcenter__schedule-caption">{{
            _T("@Call center hours")
          }}</span>
          <p class="callcenter__schedule-time">
            {{ _T("@Mon-Fri footer") }}
          </p>
          <p class="callcenter__schedule-time">
            {{ _T("@Sat-Sun footer") }}
          </p>
        </div>
      </div>
      <div class="copyright">
        <div class="copyright__payment">
          <img
            class="copyright__payment-icon"
            src="~~/assets/icons/footerMasterCardIcon.svg"
            alt="mastercard"
            loading="lazy"
          />
          <img
            class="copyright__payment-icon"
            src="~~/assets/icons/footerVisaIcon.svg"
            alt="visa"
            loading="lazy"
          />
        </div>
        <div class="copyright__w">
          <Logo
            :orange="hoverLogo"
            @mouseenter="hoverLogo = true"
            @mouseleave="hoverLogo = false"
          />
          <p class="copyright__caption">
            &#169; {{ _T("@All rights reserved") }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import Logo from "~/modules/header/components/UI/Logo.vue";

const hoverLogo = ref(false);
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;

  @include flex-container(row, center, center);

  background-color: var(--color-grey-900);

  padding: 16px;

  @include mobile {
    padding-block: 24px;
  }

  &__w {
    @include flex-container(row, space-between, center);
    gap: 32px;

    @extend %width-main;

    @include mobile {
      flex-direction: column;
    }
  }
}
.callcenter {
  @include flex-container(column, space-between, center);
  gap: 8px;

  &__link {
    @include font;
    color: white;

    transition: color 0.2s ease;

    @include mobile {
      @include font(14, 18);
    }

    &:hover {
      color: var(--color-primary-base);
    }
  }

  &__schedule {
    @include flex-container(column, space-between, center);
    gap: 8px;
  }

  &__schedule-caption {
    @include font(12, 16);
    letter-spacing: 0.02em;

    color: #8a8a8a;
  }

  &__schedule-time {
    @include font(16, 22, 700);
    letter-spacing: 0.02em;

    color: white;
  }
}
.copyright {
  @include flex-container(row, space-between, center);
  gap: 32px;

  @include mobile {
    flex-direction: column;
  }

  &__payment {
    @include flex-container(row, space-between, center);
  }

  &__w {
    width: 100%;
    max-width: 120px;

    @include flex-container(column, space-between, center);
    gap: 16px;
  }

  &__caption {
    @include font(12, 16);
    letter-spacing: 0.02em;
    text-align: center;

    color: #8a8a8a;
  }
}
</style>
